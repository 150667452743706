/* map.css */

.map-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}



.distance-container {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row; /* Changed from column to row */
  gap: 15px; /* Added more space between elements for better horizontal layout */
}


.save-button {
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 10px;
  font-size: 14px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 2;
}

.load-button {
  position: absolute;
  top: 20px;
  left: 100px;
  padding: 10px;
  font-size: 14px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 2;
}

.user-location-button{
  position: absolute;
  top: 20px;
  left: 200px;
  padding: 10px;
  font-size: 14px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 2;
}

.user-location-wrapper {
  position: relative;
  width: 30px;
  height: 30px;
  transform-origin: center; /* The mapbox translate will be applied to this wrapper */
  z-index: 999; /* Highest z-index to ensure it stays above all other elements */
}

.user-location-pointer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the pointer */
  width: 30px; /* Circle size */
  height: 30px;
  background-color: #DB4437; /* Red-ish tone */
  border: 2px solid white; /* White border */
  border-radius: 50%; /* Make it a circle */
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.user-location-arrow {
  width: 14px;
  height: 14px;
  transform-origin: center; /* Rotate around the center */
  fill: white; /* Make the arrow white */
}

.user-location-button{
  position: absolute;
  top: 20px;
  left: 200px;
  padding: 10px;
  font-size: 14px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 2;
}


.save-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

.save-overlay-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
}

.save-overlay-content h2 {
  margin-bottom: 10px;
}

.save-overlay-content pre {
  text-align: left;
  background: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  overflow: auto;
  max-height: 300px;
}

.save-overlay-content button {
  margin-top: 10px;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.milestone-marker {
  border: 2px solid black;
}
